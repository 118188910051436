/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import queryString, { ParsedQuery } from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import { view } from '@risingstack/react-easy-state';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from 'oidc-client';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { OutageForm } from './OutageForm';
import { LoadingPage } from './LoadingPage';
import { state, Site } from '../state/state.service';
import {
  DemoService,
  HealthCheck,
  QuestionResponses,
} from '../demo/demo.service';
import { NotAuthorizedPage } from './NotAuthorized';

export const PROTECTED_OUTAGE_FORM_ROUTE = '/outage-form';
export const PREVIOUS_REQUEST_URL = 'previousRequestUrl';

export interface FormPageProps {
  demoService: DemoService;
}

export interface OutageQueryParams {
  outageTimestamp: string;
  plant?: string;
  unit?: string;
  DailyProductionTotal?: number;
  Target?: number;
}

// Consists of the entire Outage Page, with the Form inside of it
export const OutageFormPage = view(({ demoService }: FormPageProps) => {
  // Tell Dayjs it will be working with UTC time formats
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const INPUT_TIME_FORMAT = 'M/D/YYYY h:mm:ss A';

  // Local state for displaying form page or not authorized page
  const [authorized, setAuthorized] = useState(false);

  // Local state for rendering Loading Page or Outage Form
  const [isLoading, setLoading] = useState(true);

  // Every time this page loads this useEffect calls the backend API to
  // retrieve Health of Service and data to fill out Form dropdowns
  useEffect(() => {
    async function getPageDetails() {
      // Check Service Health
      const healthResponse: {
        statusCode: number;
        health?: HealthCheck;
      } = await demoService.getHealth();

      if (healthResponse.statusCode === 200) {
        console.log('Health Check Successful');
        setAuthorized(true);
      } else {
        console.error('Heath Check Failed');
        setAuthorized(false);
      }

      // Get data for the Form dropdowns & previous responses for alerting in parallel
      const formDataPromise: Promise<{
        statusCode: number;
      }> = demoService.getFormData();
      const previousAnswerPromise: Promise<{
        statusCode: number;
      }> = demoService.getPreviousAnswers();

      const statusCodeArray = await Promise.all([
        formDataPromise,
        previousAnswerPromise,
      ]);

      setLoading(false);

      // Returns an anonymous function to reset state on component unmount
      // return () => {
      //   setLoading(true);
      //   setAuthorized(false);
      // };
    }

    // ! Note: Required for removing the previous request url from local storage
    // ! If removed every login this previous url will be substituted in
    localStorage.removeItem(PREVIOUS_REQUEST_URL);
    const returnToUrl = localStorage.getItem('returnToUrl');
    if (returnToUrl) {
      // Navigate to the saved URL
      window.location.href = returnToUrl;
      // Clear the saved URL from localStorage to prevent automatic redirections in the future
      localStorage.removeItem('returnToUrl');
    }

    getPageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Grabs Query String Params from URL and parses them into object/key pairs
  const { search } = useLocation();
  const queryParams: ParsedQuery = queryString.parse(search);
  // Example for ^ response:
  //   queryParamsExample = {
  //     outageTimestamp: '2021-11-05T19:15:00.687Z',
  //     plant: 'PlantName',
  //     unit: 'unitName',
  //     DailyProductionTotal: 1645,
  //     Target: 1746,
  //   };

  //  -----------------------------------------------------------

  // Plant and Unit are optional from the Event-Frame alert, but we must
  // have the outageTimestamp being responded to. We just notify user if
  // outageTimestamp is invalid
  const validatedParameters: {
    outageTimestamp: Dayjs | null;
    plant: string | null;
    unit: string | null;
    DailyProductionTotal: number | null;
    Target: number | null;
    DowntimeHours: number | null;
    startDateTimestamp: Dayjs | null;
  } = {} as any;

  // OutageTimestamp initial validation, more validation takes place before Posting
  // If outageTimestamp is string array, assign first element to validatedParameters
  if (
    queryParams.outageTimestamp &&
    Array.isArray(queryParams.outageTimestamp)
  ) {
    validatedParameters.outageTimestamp = dayjs(
      queryParams.outageTimestamp[0],
      INPUT_TIME_FORMAT,
    );
    // If outageTimestamp is string, assign to validatedParameters
  } else if (queryParams.outageTimestamp) {
    validatedParameters.outageTimestamp = dayjs(
      queryParams.outageTimestamp,
      INPUT_TIME_FORMAT,
    );
    // If outageTimestamp is undefined, set to null for locking page or doing nothing maybe ?
  } else {
    validatedParameters.outageTimestamp = null;
  }

  // If plant is undefined, do nothing
  // If plant is string, assign to validatedParameters
  // If plant is string array, assign first element to validatedParameters
  validatedParameters.plant = queryParams.plant
    ? Array.isArray(queryParams.plant)
      ? queryParams.plant[0]
      : queryParams.plant
    : null;

  // If unit is undefined do nothing
  // If unit is string, assign to validatedParameters
  // If unit is string array, assign first element to validatedParameters
  validatedParameters.unit = queryParams.unit
    ? Array.isArray(queryParams.unit)
      ? queryParams.unit[0]
      : queryParams.unit
    : null;

  validatedParameters.DailyProductionTotal = queryParams.DailyProductionTotal
    ? Array.isArray(queryParams.DailyProductionTotal)
      ? Number(queryParams.DailyProductionTotal[0])
      : Number(queryParams.DailyProductionTotal)
    : null;

  validatedParameters.Target = queryParams.Target
    ? Array.isArray(queryParams.Target)
      ? Number(queryParams.Target[0])
      : Number(queryParams.Target)
    : null;

  validatedParameters.DowntimeHours =
    queryParams.DowntimeHours &&
    !Number.isNaN(Number(queryParams.DowntimeHours)) &&
    Number.isFinite(Number(queryParams.DowntimeHours))
      ? Number(queryParams.DowntimeHours)
      : null;

  if (
    queryParams.startDateTimestamp &&
    Array.isArray(queryParams.startDateTimestamp)
  ) {
    validatedParameters.startDateTimestamp = dayjs(
      queryParams.startDateTimestamp[0],
      INPUT_TIME_FORMAT,
    );
    // If startDateTimestamp is string, assign to validatedParameters
  } else if (queryParams.startDateTimestamp) {
    validatedParameters.startDateTimestamp = dayjs(
      queryParams.startDateTimestamp,
      INPUT_TIME_FORMAT,
    );
    // If startDateTimestamp is undefined, set to null for locking page or doing nothing maybe ?
  } else {
    validatedParameters.startDateTimestamp = null;
  }

  // Handler for when Form is Submitted
  const submitHandler = async (event: QuestionResponses) => {
    try {
      if (
        !state.sitesAndUnits?.some((site: Site) => {
          return event.site === site.name;
        })
      ) {
        throw new Error('Invalid or no site selected.');
      }
      // Check if the first question has a valid response
      if (!state.question1Responses?.includes(event.question1)) {
        // eslint-disable-next-line prettier/prettier, quotes
        throw new Error(`Invalid or no response for today's loss reason.`);
      }
    } catch (err: any) {
      toast.error(err.message);
      return;
    }

    const postResponse: {
      statusCode: number;
    } = await demoService.postQuestions(event);
    if (postResponse.statusCode === 400) {
      toast.warning('Form not submitted, please contact Support if problem persists');
    } else {
        toast.success('Form Submitted Successfully');
      }
  };

  // If Page is loading return LoadingPage
  if (isLoading) {
    return <LoadingPage />;
  }

  // If GetHealth check with User's token fails, return not authorized page
  if (!authorized) {
    if (search?.length > 0) {
      localStorage.setItem(PREVIOUS_REQUEST_URL, search);
    }
    return <NotAuthorizedPage />;
  }

  // If page isn't loading and User is authorized to see Outage Form render the form
  return (
    <OutageForm queryParams={validatedParameters} onSubmit={submitHandler} />
  );
});
