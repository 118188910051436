/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ValidatedClass } from '@kaes/validated-class';
import {
  IsBoolean,
  IsFQDN,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsSemVer,
  IsString,
  IsUrl,
  Length,
  Max,
  Min,
} from 'class-validator';
import packageJson from '../../package.json';

const { name, repository, version } = packageJson;

export enum Group {
  DEPLOYMENT = 'deployment',
  BROWSER = 'browser',
}

export class ConfigService extends ValidatedClass {
  @IsIn(['production', 'development', 'test'])
  @IsOptional()
  @IsNotEmpty()
  NODE_ENV = 'development';

  @IsIn(['local', 'dev', 'uat', 'prod'])
  @IsOptional()
  ENVIRONMENT = 'local';

  @IsString()
  PACKAGE_NAME = name;

  @IsSemVer()
  PACKAGE_VERSION = version;

  @IsUrl({}, { groups: [Group.DEPLOYMENT] })
  REPOSITORY_URL = repository.url;

  @IsFQDN({}, { groups: [Group.DEPLOYMENT] })
  ROOT_DOMAIN: string;

  @IsString({ groups: [Group.DEPLOYMENT] })
  SUB_DOMAIN: string;

  @IsString({ groups: [Group.DEPLOYMENT] })
  @Length(12, 12, { groups: [Group.DEPLOYMENT] })
  AWS_ACCOUNT_ID: string;

  @IsString()
  @IsNotEmpty()
  DATADOG_APP_ID = 'a103ea02-7a7f-4265-8678-9bb77a5d8fac';

  @IsString()
  @IsNotEmpty()
  DATADOG_CLIENT_TOKEN = 'pub0dc89591fb1f1913852c08501b4beabf';

  @IsBoolean()
  DATADOG_TRACK_INTERACTIONS = true;

  @Min(1)
  @Max(100)
  @IsInt()
  DATADOG_SAMPLE_RATE = 100;

  // Below Audience was for 90 second token lifetime
  // @IsString()
  // REACT_APP_OIDC_AUDIENCE =
  //   process.env.REACT_APP_OIDC_AUDIENCE ?? 'https://refresh.kaes.io';
  @IsString()
  REACT_APP_OIDC_AUDIENCE =
    process.env.REACT_APP_OIDC_AUDIENCE ?? 'https://auth.kaes.io';

  @IsString()
  REACT_APP_OIDC_AUTHORITY =
    process.env.REACT_APP_OIDC_AUTHORITY ?? 'https://kaes.us.auth0.com';

  @IsString()
  REACT_APP_OIDC_CLIENT_ID =
    process.env.REACT_APP_OIDC_CLIENT_ID ?? 'QWZbuxdLCvVuAxwkxMpLk00XuKpf1rwc';

  @IsString()
  @IsNotEmpty()
  REACT_APP_OUTAGES_API: string;

  @IsString()
  @IsNotEmpty()
  REACT_APP_API_MAPPING: string;
}
