/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
// https://github.com/IdentityModel/oidc-client-js/wiki
import { User, UserManager } from 'oidc-client';
import { PREVIOUS_REQUEST_URL } from '../components/OutagePage';
import type { state as globalState } from '../state/state.service';

export class AuthService {
  constructor(
    private readonly userManager: UserManager,
    private state: typeof globalState,
    private windowRef = window,
  ) {
    this.userManager.events.addUserLoaded(async () => {
      console.log('userLoaded', new Date().toISOString());
      this.state.user = (await this.userManager.getUser()) ?? undefined;
    });
    // this.userManager.events.addUserUnloaded(async () => {
    //   console.log('userUnloaded');
    // });
    this.userManager.events.addAccessTokenExpiring(async () => {
      console.log('accessTokenExpiring', new Date().toISOString());
      this.state.user = await this.userManager.signinSilent();
    });
    this.userManager.events.addAccessTokenExpired(async () => {
      console.log('accessTokenExpired');
    });
    this.userManager.events.addSilentRenewError(async () => {
      console.log('silentRenewError');
      this.logout();
    });
    // this.userManager.events.addUserSignedIn(async () => {
    //   console.log('userSignedIn');
    // });
    // this.userManager.events.addUserSignedOut(async () => {
    //   console.log('userSignedOut');
    // });
    this.userManager.events.addUserSessionChanged(async () => {
      console.log('userSessionChanged');
    });
  }

  async init(): Promise<void> {
    const user = await this.getUser();
    if (user) this.state.user = user;
  }

  async login(): Promise<void> {
    await this.userManager.signinRedirect();
  }

  async loginSilent(): Promise<User> {
    console.log('Attempting to Sign In Silent via Auth Service');
    this.state.user = await this.userManager.signinSilent();
    return this.state.user;
  }

  async getUser(): Promise<ReturnType<UserManager['getUser']> | void> {
    try {
      this.state.user =
        (await this.userManager.getUser()) ??
        (await this.userManager.signinRedirectCallback());

      return this.state.user;

      // return (
      //   (await this.userManager.getUser()) ??
      //   (await this.userManager.signinRedirectCallback())
      // );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log('AuthService getUser has thrown: Message below');
      console.log(e.message);
      switch (e.message) {
        case 'No state in response':
          return null;
        case 'user token has expired':
          return this.state.user;
        default:
          throw e;
      }
    }
  }

  async logout(): Promise<void> {
    await this.userManager.removeUser();
    delete this.state.user;
    localStorage.removeItem(PREVIOUS_REQUEST_URL);
    if (this.windowRef.location.pathname !== '/') {
      this.windowRef.location.replace('/');
    }
  }
}
