/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { view } from '@risingstack/react-easy-state';
import { Button, TextArea } from '@kaes/react-components';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast } from 'react-toastify';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button as MUIButton,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

import { state, Site, Unit, LossCategory, File } from '../state/state.service';
import { QuestionResponses } from '../demo/demo.service';

// Parent is the OutagePage Component
export const OutageForm = view((props) => {
  // Tell Dayjs it will be working with UTC time formats & define
  // User friendly Time Display Format
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const USER_TIME_FORMAT = 'ddd., MMM. D YYYY, h:mm A';
  const TIME_LOCKOUT = {
    NUMBER: 9,
    UNIT: 'day',
  };

  // Checks Plant/Site Query Param and if in list of validated sites return true, else return false
  const isValidSiteProvided = state.sitesAndUnits?.some((siteObject: Site) => {
    return siteObject.name === props.queryParams.plant;
  });

  // Checks Unit Query Param and if in list of validated Units return true, else return false
  const isValidUnitProvided = state.sitesAndUnits?.some((siteObject: Site) => {
    return (
      siteObject.name === props.queryParams.plant &&
      siteObject.units.some((unitObject: Unit) => {
        return unitObject.abbreviation === props.queryParams.unit;
      })
    );
  });

  // Local states to be sent downstream for processing
  // If valid plant/site is provided then set state to auto-populate select dropdown
  const [site, setSite] = useState(
    isValidSiteProvided ? props.queryParams.plant : '',
  );
  // If valid unit is provided then set state to auto-populate select dropdown
  const [unit, setUnit] = useState(
    isValidUnitProvided
      ? state.sitesAndUnits
          ?.find((siteObject: Site) => {
            return siteObject.name === site;
          })
          ?.units.find((unitObject: Unit) => {
            return unitObject.abbreviation === props.queryParams.unit;
          })?.name || ''
      : '',
  );
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [question3, setQuestion3] = useState('');

  // Set if question 3 is required based on question 3 and question 1
  const [isIncidentIdRequired, setIsIncidentIdRequired] = useState(false);
  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState(false);
  const [incidentId, setIncidentId] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [isLoading, setLoading] = useState(false);

  // User can be locked out if no date set, but more importantly if date
  // is too old then User shouldn't be able to respond anymore
  const [userLockedOut, setUserLockedOut] = useState(false);

  useEffect(() => {
    if (
      !props.queryParams?.outageTimestamp ||
      !dayjs.utc(props.queryParams?.outageTimestamp).isValid() ||
      dayjs.utc(new Date().toISOString()).isAfter(
        dayjs
          .utc(props.queryParams.outageTimestamp)
          .add(TIME_LOCKOUT.NUMBER, TIME_LOCKOUT.UNIT as any)
          .toISOString(),
      )
    ) {
      setUserLockedOut(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.queryParams]);

  useEffect(() => {
    if (
      state.previousAnswers?.some((file: File) => {
        // If a File Key in state contains current site, unit, & outageTimestamp alert user to potential duplicate entry
        // Length checks cover edge case where empty site or unit trigger toast from includes match
        const { outageTimestamp } = props.queryParams;
        return (
          site.length > 0 &&
          unit.length > 0 &&
          outageTimestamp?.toISOString().length > 0 &&
          file.Key.includes(site.replace(/\s+/g, '-')) &&
          file.Key.includes(unit.replace(/\s+/g, '-').replace(/[/]/g, '')) &&
          file.Key.includes(outageTimestamp.toISOString())
        );
      })
    ) {
      toast.warning(
        `A response for this Outage Alert has already been submitted. Submitting again will overwrite previous response. 
        (Click here to dismiss)`,
        {
          autoClose: false,
          bodyStyle: {
            whiteSpace: 'pre-line',
          },
        },
      );
    }
  }, [props.queryParams, site, unit]);

  useEffect(() => {
    if (question1 === 'YES') {
      setIsIncidentIdRequired(false);
    } else if (
      question3 === 'Delayed Startup (Non-TA)' ||
      question3 === 'Unplanned Event' ||
      question3 === 'Extended Turnaround'
    ) {
      setIsIncidentIdRequired(true);
    } else {
      setIsIncidentIdRequired(false);
    }
  }, [question1, question3]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      /* if question 1 is yes, the options should not open and be able to select. */
      if (question1 === 'YES') {
        return;
      }

      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsCategoryDrawerOpen(open);
    };

  return (
    <>
      <div id="outage-form" className="container">
        <div className="row">
          <div className="col-md-12 mx-auto mt-4">
            <form name="outage-form">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  className="question-div production-total"
                  style={{
                    color: 'red',
                  }}
                >
                  <p id="kaes-production-values">
                    Production Actual:{' '}
                    {props.queryParams.DailyProductionTotal ?? 'N/A'}
                  </p>
                </div>
                <div
                  className="question-div production-target"
                  style={{
                    color: 'green',
                  }}
                >
                  <p id="kaes-production-values">
                    Production Target: {props.queryParams.Target ?? 'N/A'}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {/* Question Block for which Site */}
                <div className="kaes-site question-div" id="kaes-site">
                  <label htmlFor="kaes-site">Affected Site</label>
                  <Select
                    className="question-select"
                    name="kaes-site"
                    id="kaes-site"
                    form="outage-form"
                    // autoFocus
                    isClearable
                    isSearchable
                    isDisabled={userLockedOut}
                    placeholder="Choose Site"
                    options={
                      // If sites are set in state then return list for Users, else set options to empty array
                      state.sitesAndUnits
                        ? state.sitesAndUnits?.map((siteObject: Site) => ({
                            value: siteObject.name,
                            label: siteObject.name,
                          }))
                        : []
                    }
                    value={
                      site
                        ? {
                            value: site,
                            label: site,
                          }
                        : undefined
                    }
                    onChange={(e: any) => {
                      if (e) {
                        setSite(e.value);
                      } else {
                        setSite('');
                      }
                      setUnit('');
                    }}
                  />
                </div>
                {/* Question Block for which Unit */}
                <div className="kaes-unit question-div" id="kaes-unit">
                  <label htmlFor="kaes-unit">Affected Unit</label>
                  <Select
                    className="question-select"
                    name="kaes-unit"
                    id="kaes-unit"
                    form="outage-form"
                    isClearable
                    isSearchable
                    isDisabled={userLockedOut}
                    placeholder="Choose Unit"
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue ? 'Pick a Site first' : 'No results found'
                    }
                    options={
                      site && state.sitesAndUnits
                        ? state.sitesAndUnits
                            .find((siteObject: Site) => {
                              return siteObject.name === site;
                            })
                            ?.units.map((unitObject: Unit) => {
                              return {
                                value: unitObject.name,
                                label: unitObject.name,
                              };
                            })
                        : []
                    }
                    value={unit ? { value: unit, label: unit } : undefined}
                    onChange={(e: any) => {
                      if (e) {
                        setUnit(e.value);
                      } else {
                        setUnit('');
                      }
                    }}
                  />
                </div>
                {/* Question Block for which Unit */}
                <div className="question-div" id="kaes-outage-timestamp">
                  <label htmlFor="kaes-outage-timestamp">
                    Outage Timestamp
                  </label>
                  <p className="kaes-outage-timestamp">
                    {props.queryParams.outageTimestamp
                      ? props.queryParams.outageTimestamp.format(
                          USER_TIME_FORMAT,
                        )
                      : 'No Timestamp Detected'}
                  </p>
                </div>
                <div
                  title="Over the last production day"
                  className="question-div"
                  id="kaes-outage-timestamp"
                >
                  <label htmlFor="kaes-outage-timestamp">Downtime Hours</label>
                  <p className="kaes-outage-timestamp">
                    {props.queryParams.DowntimeHours ?? 'Not Detected'}
                  </p>
                </div>
                {/* <div
                  style={{
                    // background: 'green',
                    paddingLeft: '10vw',
                  }}
                >
                  <ToastContainer theme="dark" />
                </div> */}
              </div>
              {/* Question Block for Question 1 */}
              <div className="question1 question-div" id="question1">
                <label htmlFor="question1">
                  Is today&apos;s loss reason the same as yesterday?
                </label>
                <Select
                  className="question-select"
                  name="question1"
                  id="question1"
                  form="outage-form"
                  isClearable
                  isSearchable
                  isDisabled={userLockedOut}
                  placeholder="Choose Here"
                  options={
                    state.question1Responses
                      ? state.question1Responses.map((response: string) => {
                          return { value: response, label: response };
                        })
                      : []
                  }
                  onChange={(e: any) => {
                    setDescription('');
                    if (e) {
                      setQuestion1(e.value);
                    } else {
                      setQuestion1('');
                    }
                  }}
                />
              </div>
              {/* Question Block for Question 2 */}
              <div className="question2 question-div">
                <label htmlFor="question2">
                  Is this loss due to another unit?
                </label>
                <Select
                  className="question-select"
                  name="question2"
                  id="question2"
                  form="outage-form"
                  isClearable
                  isSearchable
                  isDisabled={userLockedOut || question1 === 'YES'}
                  placeholder="Choose Here"
                  options={
                    state.question2Responses
                      ? state.question2Responses.map((response: string) => {
                          return { value: response, label: response };
                        })
                      : []
                  }
                  onChange={(e: any) => {
                    if (e) {
                      setQuestion2(e.value);
                    } else {
                      setQuestion2('');
                    }
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* Question Block for Question 3 */}
                <div className="question3 question-div">
                  <label htmlFor="question3">
                    What is the utilization loss category?
                  </label>
                  <Select
                    className="question-select select-extended"
                    name="question3"
                    id="question3"
                    form="outage-form"
                    isClearable
                    isSearchable={false}
                    isDisabled={userLockedOut || question1 === 'YES'}
                    placeholder="Choose Here"
                    options={
                      state.question3Responses
                        ? state.question3Responses.map(
                            (response: LossCategory) => {
                              return {
                                value: response.name,
                                label: response.name,
                              };
                            },
                          )
                        : []
                    }
                    value={
                      question3
                        ? { value: question3, label: question3 }
                        : undefined
                    }
                    onChange={(e: any) => {
                      if (e) {
                        setQuestion3(e.value);
                      } else {
                        setQuestion3('');
                      }
                    }}
                  />
                </div>
                <div className="question-div" id="kaes-category-descriptions">
                  <MUIButton
                    sx={{ marginTop: '2.5rem' }}
                    onClick={toggleDrawer(true)}
                  >
                    Loss Category Descriptions
                  </MUIButton>
                  <Drawer
                    anchor="right"
                    open={isCategoryDrawerOpen}
                    onClose={toggleDrawer(false)}
                  >
                    <Box
                      sx={{ width: 'auto' }}
                      role="presentation"
                      onClick={toggleDrawer(false)}
                      onKeyDown={toggleDrawer(false)}
                    >
                      <List>
                        {state.question3Responses === undefined
                          ? []
                          : state.question3Responses.map(
                              (category: LossCategory) => (
                                <ListItem
                                  button
                                  key={category.name}
                                  onClick={() => {
                                    setQuestion3(category.name);
                                  }}
                                >
                                  <ListItemIcon>
                                    <DescriptionIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={category.name}
                                    secondary={category.description}
                                  />
                                </ListItem>
                              ),
                            )}
                      </List>
                    </Box>
                  </Drawer>
                </div>
              </div>
              <div className="question-div">
                <TextArea
                  value={description}
                  style={{ resize: 'none' }}
                  draggable={false}
                  label="Event Description"
                  disabled={userLockedOut || question1 === 'YES'}
                  rows={1}
                  maxLength={100}
                  helpText="Event Description is used for CauseLinkID"
                  onChange={(e: any) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="question-div">
                <TextArea
                  value={incidentId}
                  style={{ resize: 'none' }}
                  draggable={false}
                  disabled={userLockedOut}
                  label={`Incident ID ${
                    isIncidentIdRequired ? '(Required)' : '(Optional)'
                  }`}
                  rows={1}
                  maxLength={100}
                  helpText="Incident ID is the Enablon Number (numeric only)"
                  onChange={(e: any) => {
                    setIncidentId(e.target.value);
                  }}
                  onKeyPress={(e: any) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="notes question-div">
                <TextArea
                  label="Notes (Optional)"
                  rows={4}
                  maxLength={65535}
                  disabled={userLockedOut}
                  helpText="Notes is an optional field for any related Outage details/notes"
                  onChange={(e: any) => {
                    setNotes(e.target.value);
                  }}
                />
              </div>
              {!isLoading && (
                <Button
                  float
                  className="btn-primary"
                  style={{
                    marginRight: '1rem',
                    marginBottom: '1rem',
                    fontWeight: 'bold',
                  }}
                  form="outage-form"
                  type="submit"
                  disabled={userLockedOut}
                  name="Submit"
                  onClick={async (e) => {
                    const responses: QuestionResponses = {
                      dailyProductionTotal:
                        props.queryParams.DailyProductionTotal !== null
                          ? Number(props.queryParams.DailyProductionTotal)
                          : null,
                      dailyProductionTarget:
                        props.queryParams.Target !== null
                          ? Number(props.queryParams.Target)
                          : null,
                      outageTimestamp: props.queryParams.outageTimestamp
                        ? props.queryParams.outageTimestamp.toISOString()
                        : '',
                      downtimeHours:
                        props.queryParams.DowntimeHours !== null
                          ? props.queryParams.DowntimeHours?.toString()
                          : '',
                      startDateTimestamp: props.queryParams.startDateTimestamp
                        ? props.queryParams.startDateTimestamp.toISOString()
                        : '',
                      site,
                      unit,
                      question1,
                      question2:
                        question1 === 'YES'
                          ? 'Look at previous day event'
                          : question2,
                      question3:
                        question1 === 'YES'
                          ? 'Look at previous day event'
                          : question3,
                      incidentId: incidentId === '' ? null : Number(incidentId),
                      description,
                      notes,
                    };
                    setLoading(true);
                    await props.onSubmit(responses);
                    setLoading(false);
                  }}
                >
                  Submit
                </Button>
              )}
              {isLoading && (
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    form="outage-form"
                    style={{ display: 'flex', alignItems: 'center' }}
                    type="submit"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    />
                    Submit
                  </button>
                </div>
              )}

              {/* <div className="parent-element">
                <div className="child-element">
                  <p>{`Sites: ${state.sitesAndUnits?.length}` || 'Empty1'}</p>
                  <p>
                    {`Units: ${Object.keys(state.unitMappings).length}` ||
                      'Empty2'}
                  </p>
                  <p>
                    {`Q1 Options: ${state.question1Responses?.length}` ||
                      'Empty3'}
                  </p>
                  <p>
                    {`Q2 Options: ${state.question2Responses?.length}` ||
                      'Empty3'}
                  </p>
                  <p>
                    {`Q3 Options: ${state.question3Responses?.length}` ||
                      'Empty3'}
                  </p>
                </div>
                <div className="child-element" style={{ background: 'beige' }}>
                  <p>{`Site State: ${site || 'Empty'}`}</p>
                  <p>{`Site State: ${unit || 'Empty'}`}</p>
                  <p>{`Q1 State: ${question1 || 'Empty'}`}</p>
                  <p>{`Q2 State: ${question2 || 'Empty'}`}</p>
                  <p>{`Q3 State: ${question3 || 'Empty'}`}</p>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
});
