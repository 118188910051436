import React from 'react';

export const LoadingPage = (): JSX.Element => (
  <>
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 mx-auto mt-5"
          // style={{ justifyContent: 'center' }}
        >
          Loading Content
        </div>
      </div>
    </div>
  </>
);
