/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@kaes/react-components';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthService } from '.';
import { State, state } from '../state/state.service';
import { PREVIOUS_REQUEST_URL } from '../components/OutagePage';

interface LoginButtonProps {
  onClick: React.MouseEventHandler;
}

interface AvatarProps {
  onClick: React.MouseEventHandler;
  src: string | undefined;
}

interface LoginComponentProps {
  user: State['user'];
  authService: AuthService;
  // loginOnClick: (...args: any[]) => null;
}

// const Profile: ReactFunction = () => {};
//
export const Avatar = ({ onClick, src }: AvatarProps): JSX.Element => {
  return (
    <img
      height="45px"
      src={src}
      className="rounded-circle pointer mx-3"
      alt="user profile"
      onClick={onClick}
    />
  );
};

export const LoginButton = ({ onClick }: LoginButtonProps): JSX.Element => {
  return (
    <Button float className="btn-outline-danger" onClick={onClick}>
      Login
    </Button>
  );
};

export const LoginComponent = ({
  user,
  authService,
}: // loginOnClick,
LoginComponentProps): JSX.Element => {
  const history = useHistory();
  // const login = () => {
  //   loginOnClick();
  //   return authService.login();
  // };
  const login = () => authService.login();
  const profileRedirect = () => {
    // Save the full current URL to state
    localStorage.setItem('returnToUrl', window.location.href);
    // Redirect to the profile page and pass along the full current URL as state
    history.push('/user-profile');
  };

  const previousRequestUrl: string | null =
    localStorage.getItem(PREVIOUS_REQUEST_URL);

  // If user is valid and previousRequestUrl is populated, redirect to previous url
  // Else if previousRequestUrl is empty, just return profile page redirect
  if (user && previousRequestUrl && previousRequestUrl.length > 0) {
    console.log('Search Restored');
    history.replace(`/outage-form${previousRequestUrl}`);
    return <Avatar onClick={profileRedirect} src={user.profile.picture} />;
  }

  if (user) {
    return <Avatar onClick={profileRedirect} src={user.profile.picture} />;
  }

  return <LoginButton onClick={login} />;
};

export const Login = view(
  ({
    authService,
  }: // loginOnClick,
  {
    authService: AuthService;
  }) => (
    // loginOnClick: (...args: any[]) => null;
    <LoginComponent
      user={state.user}
      authService={authService}
      // loginOnClick={loginOnClick}
    />
  ),
);

// ! ----Login On Click Experiment Below----------------------------------------------------------
// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// import { Button } from '@kaes/react-components';
// import { view } from '@risingstack/react-easy-state';
// import React from 'react';
// import { useHistory } from 'react-router-dom';
// import { AuthService } from '.';
// import { State, state } from '../state/state.service';

// interface LoginButtonProps {
//   onClick: React.MouseEventHandler;
// }

// interface AvatarProps {
//   onClick: React.MouseEventHandler;
//   src: string | undefined;
// }

// interface LoginComponentProps {
//   user: State['user'];
//   authService: AuthService;
//   loginOnClick: (...args: any[]) => null;
// }

// // const Profile: ReactFunction = () => {};
// //
// export const Avatar = ({ onClick, src }: AvatarProps): JSX.Element => {
//   return (
//     <img
//       height="45px"
//       src={src}
//       className="rounded-circle pointer mx-3"
//       alt="user profile"
//       onClick={onClick}
//     />
//   );
// };

// export const LoginButton = ({ onClick }: LoginButtonProps): JSX.Element => {
//   return (
//     <Button float className="btn-outline-danger" onClick={onClick}>
//       Login
//     </Button>
//   );
// };

// export const LoginComponent = ({
//   user,
//   authService,
//   loginOnClick,
// }: LoginComponentProps): JSX.Element => {
//   const history = useHistory();
//   const login = () => {
//     loginOnClick();
//     return authService.login();
//   };
//   const redirect = () => history.push('/user-profile');

//   if (user) {
//     return <Avatar onClick={redirect} src={user.profile.picture} />;
//   }
//   return <LoginButton onClick={login} />;
// };

// export const Login = view(
//   ({
//     authService,
//     loginOnClick,
//   }: {
//     authService: AuthService;
//     loginOnClick: (...args: any[]) => null;
//   }) => (
//     <LoginComponent
//       user={state.user}
//       authService={authService}
//       loginOnClick={loginOnClick}
//     />
//   ),
// );
