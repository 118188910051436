/* eslint-disable @typescript-eslint/no-explicit-any */
import { observe } from '@nx-js/observer-util';
import { store } from '@risingstack/react-easy-state';
import cache from 'lscache';
import { User } from 'oidc-client';

export interface Unit {
  name: string;
  abbreviation: string;
}

export interface Site {
  name: string;
  abbreviation: string;
  units: Unit[];
}
export interface QuestionResponseFile {
  title: string;
  selectFrom: string[];
}

export interface LossCategory {
  name: string;
  description: string;
}

export interface File {
  Key: string;
  Size: number;
  LastModified: string;
}

export interface State {
  demo?: Record<string, unknown>;
  user?: User;
  sitesAndUnits?: Site[];
  question1Responses?: string[];
  question2Responses?: string[];
  question3Responses?: LossCategory[];
  previousAnswers?: File[];
  error?: Error;
}

const localCache = (
  state: Record<string, any>,
  path: string,
  exp?: number,
): boolean | void =>
  state?.[path] ? cache.set(path, state[path], exp) : cache.remove(path);

const current: State = {
  user: cache.get('user'),
};

export const state: State = store(current);

observe(() => {
  localCache(state, 'user');
});
