/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserRouter, NavLink, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import { Navigation } from '@kaes/react-components';
import React, { useState } from 'react';
import { User } from 'oidc-client';
import {
  OutageFormPage,
  PROTECTED_OUTAGE_FORM_ROUTE,
} from './components/OutagePage';
import { state } from './state/state.service';
import { Login } from './authentication/Login';
import { Authentication } from './authentication';
import { authService, demoService } from './app.module';
import { PageNotFound } from './components/PageNotFound';
import { HomePage, HOME_PAGE_ROUTE } from './components/Home';
import { UserProfilePage, USER_PROFILE_ROUTE } from './components/UserProfile';
import './datadog/datadog.service';
import 'react-toastify/dist/ReactToastify.css';

function App(): JSX.Element {
  const [open, toggle] = useState(false);

  // TODO in Future: Enable hiding of Outage Form if user not logged in
  const [loggedIn, setLoggedIn] = useState(true);

  return (
    <Authentication authService={authService}>
      <BrowserRouter>
        <Navigation
          imgURL="/Koch_Logo.png"
          height={50}
          color="white"
          bg="dark"
          className="text-capitalize"
          open={open}
          toggle={toggle}
        >
          {loggedIn && (
            <NavLink to={PROTECTED_OUTAGE_FORM_ROUTE} className="mx-2">
              Outage Form
            </NavLink>
          )}

          <Login authService={authService} />
        </Navigation>

        <ToastContainer theme="dark" />

        <Switch>
          <Route exact path={HOME_PAGE_ROUTE} component={HomePage} />
          <Route
            exact
            path={PROTECTED_OUTAGE_FORM_ROUTE}
            component={() => <OutageFormPage demoService={demoService} />}
          />
          <Route
            exact
            path={USER_PROFILE_ROUTE}
            component={() => <UserProfilePage authService={authService} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Authentication>
  );
}

export default App;
