import React from 'react';

export const NotAuthorizedPage = (): JSX.Element => (
  <>
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 mx-auto mt-5"
          // style={{ justifyContent: 'center' }}
        >
          <h2>User Not Authorized</h2>
          <p>Please try to log out and log back in again.</p>
          <p>
            If you continue to experience issues, please reach out to Support.
          </p>
        </div>
      </div>
    </div>
  </>
);
