/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
import axios, { AxiosResponse, AxiosStatic } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { User } from 'oidc-client';
import { toast } from 'react-toastify';
import type { State } from '../state/state.service';
import type { AuthService } from '../authentication';
import { ConfigService } from '../config/config.service';

export interface HealthCheck {
  health: {
    status: string;
  };
  name: string;
  version: string;
}

export interface QuestionResponses {
  dailyProductionTotal: number | null;
  dailyProductionTarget: number | null;
  outageTimestamp: string;
  downtimeHours: string;
  startDateTimestamp: string;
  site: string;
  unit: string;
  question1: string;
  question2: string;
  question3: string;
  incidentId?: number | null;
  description?: string;
  notes?: string;
}

export interface PITagValue {
  value: string;
  timestamp: string;
}
export interface PITag {
  tagName: string;
  values: PITagValue[];
}
export interface PostBody {
  plant: string;
  unit: string;
  sameAsYesterday: string;
  anotherUnit: string;
  lossCategory: string;
  userName?: string;
  userEmail?: string;
  outageTimeStamp: string;
  downtimeHours: string;
  startDateTimestamp: string;
  incidentId?: number | null;
  description?: string;
  notes?: string;
  dailyProductionTotal: number;
  dailyProductionTarget: number;
}

export class DemoService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly authService: AuthService,
    private readonly state: State,
    private readonly config: ConfigService,
  ) {}

  async runAuth(): Promise<User> {
    let user = await this.authService.getUser();

    // If User comes back null or undefined then display not logged in error
    // If User exists and token has expired or is within 60 seconds of expiring
    // then attempt a silent sign in
    if (!user) {
      throw new Error('NotLoggedInError: User is not logged in');
    } else if (user?.expired === true || user?.expires_in < 60) {
      user = await this.authService.loginSilent();
    }

    return user;
  }

  async getHealth(): Promise<{
    statusCode: number;
    health?: HealthCheck;
  }> {
    try {
      const user: User = await this.runAuth();

      const healthResponse: AxiosResponse<HealthCheck> = await this.backend.get(
        `${this.config.REACT_APP_OUTAGES_API}${this.config.REACT_APP_API_MAPPING}/healthz`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      return { statusCode: 200, health: healthResponse.data };
    } catch (err: any) {
      this.state.error = err;
      console.warn(err);
      return { statusCode: 400 };
    }
  }

  async getFormData(): Promise<{
    statusCode: number;
  }> {
    try {
      const user: User = await this.runAuth();

      // Get backend data and set mapping object, and question responses in state
      const questionResponseFiles = [
        'question1.json',
        'question2.json',
        'question3.json',
        'sitesAndUnits.json',
      ];

      const fileResponses: Promise<AxiosResponse>[] = questionResponseFiles.map(
        (file) => {
          return axios.get(
            `${this.config.REACT_APP_OUTAGES_API}${this.config.REACT_APP_API_MAPPING}/question-responses/single/${file}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
              },
            },
          );
        },
      );

      const dataResults: AxiosResponse[] = await Promise.all([
        ...fileResponses,
      ]);

      // If data is stringified then parse it, else return as is
      const parsedDataResults = dataResults.map(
        (axiosResponse: AxiosResponse) => {
          if (typeof axiosResponse.data === 'string') {
            return { ...axiosResponse, data: JSON.parse(axiosResponse.data) };
          } else {
            return axiosResponse;
          }
        },
      );

      // Set state
      parsedDataResults.forEach((axiosResponse: AxiosResponse) => {
        if (axiosResponse.data.title === 'question1') {
          this.state.question1Responses = axiosResponse.data.selectFrom;
        } else if (axiosResponse.data.title === 'question2') {
          this.state.question2Responses = axiosResponse.data.selectFrom;
        } else if (axiosResponse.data.title === 'question3') {
          this.state.question3Responses = axiosResponse.data.selectFrom;
        } else if (axiosResponse.data.title === 'sitesAndUnits') {
          this.state.sitesAndUnits = axiosResponse.data.sites;
        }
      });

      return { statusCode: 200 };
    } catch (err: any) {
      this.state.error = err;
      console.warn(err);
      return { statusCode: 400 };
    }
  }

  async getPreviousAnswers(): Promise<{ statusCode: number }> {
    try {
      const user: User = await this.runAuth();
      const previousAnswerFiles: AxiosResponse = await axios.get(
        `${this.config.REACT_APP_OUTAGES_API}${this.config.REACT_APP_API_MAPPING}/question-answers/bulk`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      const parsedPreviousAnswerFiles =
        typeof previousAnswerFiles.data === 'string'
          ? {
              ...previousAnswerFiles,
              data: JSON.parse(previousAnswerFiles.data),
            }
          : previousAnswerFiles;

      this.state.previousAnswers = parsedPreviousAnswerFiles.data;

      return { statusCode: 200 };
    } catch (err: any) {
      this.state.error = err;
      console.warn(err);
      return { statusCode: 400 };
    }
  }

  async postQuestions(
    responses: QuestionResponses,
  ): Promise<{ statusCode: number }> {
    try {
      const user: User = await this.runAuth();

      // Tell Dayjs it will be working with UTC time formats
      dayjs.extend(utc);
      // If no timestamp or invalid timestamp provided don't process event
      if (
        !responses?.outageTimestamp ||
        !dayjs.utc(responses.outageTimestamp).isValid()
      ) {
        throw new Error('Cannot process event with invalid Outage Timestamp');
      }

      if (responses.downtimeHours === '')
        throw new Error('Cannot process event with invalid Downtime Hours');

      if (
        responses.startDateTimestamp === '' ||
        !dayjs.utc(responses.startDateTimestamp).isValid()
      )
        throw new Error(
          'Cannot process event with invalid Start Date Timestamp',
        );
      // If question1 has a "yes" response, then no check is necessary. However, if question1 has a "no" response, then question3 is required.
      if (responses.question1 === 'NO' && responses.question3 === '')
        throw new Error(
          'Cannot process event with invalid Utilization Loss Category',
        );

      if (responses.question1 === 'NO' && responses.description === '')
        throw new Error('Cannot process event with invalid Event Description');

      if (responses.dailyProductionTotal === null) {
        throw new Error('Production Total is required');
      }

      if (responses.dailyProductionTarget === null) {
        throw new Error('Production Target is required');
      } else if (responses.dailyProductionTarget <= 0) {
        throw new Error('Production Target must be greater than 0');
      }
      // If Incident ID is required, but is not provided, then throw error
      if (
        (responses.question3 === 'Delayed Startup (Non-TA)' ||
          responses.question3 === 'Unplanned Event' ||
          responses.question3 === 'Extended Turnaround') &&
        responses.incidentId === null
      ) {
        throw new Error('Incident ID is required');
      }

      const postBody: PostBody = {
        plant: responses.site,
        unit: responses.unit,
        sameAsYesterday: responses.question1,
        anotherUnit: responses.question2,
        lossCategory: responses.question3,
        userName: user.profile.name,
        userEmail: user.profile.email,
        outageTimeStamp: responses.outageTimestamp,
        downtimeHours: responses.downtimeHours,
        startDateTimestamp: responses.startDateTimestamp,
        incidentId: responses.incidentId,
        description: responses.description,
        notes: responses.notes,
        dailyProductionTotal: responses.dailyProductionTotal,
        dailyProductionTarget: responses.dailyProductionTarget,
      };

      // Need to hit Lambda1 that accepts data and writes to location (S3, Dynamo, etc)

      const response: AxiosResponse<string> = await axios.post(
        `${this.config.REACT_APP_OUTAGES_API}${this.config.REACT_APP_API_MAPPING}/outage-recording`,
        postBody,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      console.log('post response', response);

      return { statusCode: 200 };
    } catch (err: any) {
      this.state.error = err;
      toast.error(err.message);
      return { statusCode: 400 };
    }
  }

  // async getHealthz(): Promise<void> {
  //   try {
  //     const user = await this.authService.getUser();
  //     if (!user) throw new Error('NotLoggedInError: user is not logged in');

  //     const { data } = await this.backend.get(
  //       'https://apig.poc-test.kaes.io/demo-prod.v0/healthz',
  //       {
  //         headers: {
  //           authorization: `Bearer ${user.access_token}`,
  //         },
  //       },
  //     );
  //     this.state.demo = data;
  //   } catch (e: any) {
  //     this.state.error = e;
  //   }
  // }
}
